import Vue from "vue";

const globalMixin = {
    methods: {
        showError(err) {
            let title = err.message ? err.message : this.t("unexpected_error");
            let message = null;

            if (err.errors) {
                message = Object.values(err.errors)[0][0];
            } else if (err.error) {
                message = err.error;
            }
            this.$notify({
                type: "warning",
                title: title,
                message: message,
            });
        },
        showSuccess(message, title = this.t("success")) {
            this.$notify({
                type: "success",
                title: title,
                message: message,
            });
        },
        mapVisit0(visit_0) {
            return visit_0;
        },
        mapVisit1(visit_1) {
            // visit_1
            visit_1.supplement_usage = this.convertString(visit_1.supplement_usage);

            //visit_1 hads
            Object.keys(visit_1.hads).forEach((_f) => {
                if (typeof visit_1.hads[_f] === "number" && _f !== "is_completed") {
                    visit_1.hads[_f] = this.convertString(visit_1.hads[_f]);
                }
            });

            //visit_1 pss
            Object.keys(visit_1.pss).forEach((_f) => {
                if (typeof visit_1.pss[_f] === "number" && _f !== "is_completed") {
                    visit_1.pss[_f] = this.convertString(visit_1.pss[_f]);
                }
            });

            return visit_1;
        },
        mapVisit2(visit_2) {
            visit_2.serious_adverse_events = visit_2.serious_adverse_events.map(
                (_f) => this.mapSae(_f)
            );
            return visit_2;
        },

        mapVisit3(visit_3) {
            visit_3.supplement_usage = this.convertString(visit_3.supplement_usage);
            visit_3.serious_adverse_events_status = this.convertString(
                visit_3.serious_adverse_events_status
            );
            visit_3.serious_adverse_events = visit_3.serious_adverse_events.map(
                (_f) => this.mapSae(_f)
            );

            //visit_3 hads
            Object.keys(visit_3.hads).forEach((_f) => {
                if (typeof visit_3.hads[_f] === "number") {
                    visit_3.hads[_f] = this.convertString(visit_3.hads[_f]);
                }
            });

            //visit_3 pss
            Object.keys(visit_3.pss).forEach((_f) => {
                if (typeof visit_3.pss[_f] === "number") {
                    visit_3.pss[_f] = this.convertString(visit_3.pss[_f]);
                }
            });

            return visit_3;
        },
        mapVisit4(visit_4) {
            visit_4.supplement_usage = this.convertString(visit_4.supplement_usage);
            visit_4.serious_adverse_events = visit_4.serious_adverse_events.map(
                (_f) => this.mapSae(_f)
            );
            return visit_4;
        },
        mapSae(sae) {
            Object.keys(sae).forEach((_f) => {
                if (typeof sae[_f] === "number") {
                    sae[_f] = this.convertString(sae[_f]);
                }
            });
            return sae;
        },
        convertString(val) {
            if (val === null) return val;
            return val.toString();
        },
    },
};

Vue.mixin(globalMixin);