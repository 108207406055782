import axios from "axios";

export default {
    namespaced: true,

    state: {
        centerRandomizationNumbers: [],
    }, //STATE

    getters: {}, //GETTERS

    mutations: {
        SET_RANDOMIZATION_NUMBERS: (state, numbers) => {
            state.centerRandomizationNumbers = numbers;
        },
    }, // MUTATIONS

    actions: {
        FETCH_RANDOMIZATION: ({ rootState }) => {
            return new Promise((resolve, reject) => {
                axios({
                        url: `${process.env.VUE_APP_API_URL}randomization`,
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${rootState.auth.token}`,
                        },
                    })
                    .then((resp) => {
                        resolve(resp.data.data);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        FETCH_CENTER_RANDOMIZATION: ({ rootState, commit }, center_id) => {
            return new Promise((resolve, reject) => {
                axios({
                        url: `${process.env.VUE_APP_API_URL}center_randomizations/${center_id}`,
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${rootState.auth.token}`,
                        },
                    })
                    .then((resp) => {
                        commit("SET_RANDOMIZATION_NUMBERS", resp.data.data);
                        resolve(resp.data.data);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        UPDATE_CENTER_RANDOMIZATION: ({ rootState }, payload) => {
            return new Promise((resolve, reject) => {
                axios({
                        url: `${process.env.VUE_APP_API_URL}randomization_center`,
                        method: "PUT",
                        data: payload,
                        headers: {
                            Authorization: `Bearer ${rootState.auth.token}`,
                        },
                    })
                    .then((resp) => {
                        resolve(resp.data.data);
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        ADD_RANDOMIZATION: ({ commit, state }, payload) => {
            if (
                state.centerRandomizationNumbers.findIndex((r) => r.id === payload.id) >
                -1
            )
                return;
            commit("SET_RANDOMIZATION_NUMBERS", [
                ...state.centerRandomizationNumbers,
                payload,
            ]);
        },
    }, //ACTIONS
};