<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="
        navbar-search navbar-search-dark
        form-inline
        mr-3
        d-none d-md-flex
        ml-lg-auto
      "
      @submit.prevent="search"
    >
      <div class="form-group mb-0">
        <base-input
          :placeholder="t('search_patient')"
          class="input-group-alternative"
          alternative=""
          addon-right-icon="fas fa-search"
          v-model="searchTerm"
        >
        </base-input>
      </div>
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div
            class="media align-items-center"
            slot="title"
            style="cursor: pointer"
          >
            <span class="avatar avatar-sm rounded-circle">
              <img
                alt="Image placeholder"
                src="/img/user.svg"
                style="background-color: #fff"
              />
            </span>
            <div class="media-body ml-2 d-none d-lg-block cur">
              <span class="mb-0 text-sm font-weight-bold">
                {{ $store.state.auth.user.full_name }}
              </span>
            </div>
          </div>

          <template>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>{{ t("account_information") }}</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <a @click="logout" class="dropdown-item" style="cursor: pointer">
              <i class="ni ni-user-run"></i>
              <span>{{ t("logout") }}</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
    };
  },
  computed: {
    searchTerm: {
      get() {
        return this.$store.state.searchTerm;
      },
      set(val) {
        this.$store.state.searchTerm = val;
      },
    },
  },
  watch: {
    $route(to) {
      if (to.name === "patients") this.searchTerm = "";
    },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.dispatch("auth/AUTH_LOGOUT");
      this.$router.push({ name: "login" });
    },
    search() {
      if (this.searchTerm) {
        this.$router.push({
          name: "patientSearch",
          params: {
            searchParam: encodeURI(this.searchTerm),
          },
        });
      }
    },
  },
  created() {
    if (this.$route.params.searchParam)
      this.searchTerm = decodeURI(this.$route.params.searchParam);
  },
};
</script>
