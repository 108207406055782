import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "./plugins/axios";
import "./plugins/vee-validate";
import { VueWait } from "./plugins/wait";
import "./plugins/i18n";
import "./plugins/multiselect";
import "./plugins/vue-good-table";
import "./plugins/mask";

import store from "./store/index";

import "./filters/index";

import "./mixins/helper";

Vue.config.productionTip = false;

Vue.use(ArgonDashboard);
new Vue({
    router,
    store,
    wait: new VueWait({
        useVuex: true,
    }),

    render: (h) => h(App),
}).$mount("#app");