import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import Auth from "./auth";
import User from "./user";
import Center from "./center";
import Patient from "./patient";
import Randomization from "./randomization";

export default new Vuex.Store({
    state: {
        count: 0,
        searchTerm: "",
    },
    mutations: {},
    modules: {
        auth: Auth,
        user: User,
        center: Center,
        patient: Patient,
        randomization: Randomization,
    },
});