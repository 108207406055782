var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$wait.is('fetchUser'))?_c('base-loader'):_c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"CROTURK","title":"CROTURK"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.t('patients'),
          icon: 'ni ni-badge text-primary',
          path: '/patients',
        }}}),(_vm.user.level === 'MASTER')?_c('sidebar-item',{attrs:{"link":{
          name: _vm.t('randomization_name'),
          icon: 'ni ni-archive-2 text-primary',
          path: '/randomization',
        }}}):_vm._e(),(_vm.user.level === 'MASTER')?_c('sidebar-item',{attrs:{"link":{
          name: _vm.t('centers'),
          icon: 'ni ni-shop text-primary',
          path: '/centers',
        }}}):_vm._e(),(_vm.user.level === 'MASTER')?_c('sidebar-item',{attrs:{"link":{
          name: _vm.t('users'),
          icon: 'ni ni-circle-08 text-primary',
          path: '/users',
        }}}):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.exportModalStatus = true}}},[[_c('i',{staticClass:"ni ni-cloud-download-95 text-primary"}),_c('span',{staticClass:"nav-link-text"},[_vm._v(_vm._s(_vm.t("download")))])]],2)])],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1),_c('export-modal',{attrs:{"modalStatus":_vm.exportModalStatus},on:{"update:modalStatus":function($event){_vm.exportModalStatus=$event},"update:modal-status":function($event){_vm.exportModalStatus=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }