import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import store from "./store/index";
Vue.use(Router);

const authGuard = (to, from, next) => {
    if (store.getters["auth/isAuthenticated"]) {
        next();
        return;
    }
    next({ name: "login" });
};

export default new Router({
    linkExactActiveClass: "active",
    mode: "history",
    routes: [{
            path: "/",
            redirect: { name: "patients" },
            component: DashboardLayout,
            beforeEnter: authGuard,
            children: [{
                    path: "/dashboard",
                    name: "dashboard",
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ "./views/Dashboard.vue"),
                },
                {
                    path: "/icons",
                    name: "icons",
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ "./views/Icons.vue"),
                },
                {
                    path: "/profile",
                    name: "profile",
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ "./views/UserProfile.vue"),
                },
                {
                    path: "/maps",
                    name: "maps",
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ "./views/Maps.vue"),
                },
                {
                    path: "/tables",
                    name: "tables",
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ "./views/Tables.vue"),
                },
                {
                    path: "/patients",
                    name: "patients",
                    component: () =>
                        import ("./views/Patients.vue"),
                },
                {
                    path: "/patients/search/:searchParam",
                    name: "patientSearch",
                    component: () =>
                        import ("./views/Patients.vue"),
                },
                {
                    path: "/patients/:patientId",
                    name: "patient",
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ "./views/PatientForm.vue"),
                },
                {
                    path: "/new-patient",
                    name: "newPatient",
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ "./views/PatientForm.vue"),
                },
                {
                    path: "/users",
                    name: "users",
                    component: () =>
                        import ("./views/Users.vue"),
                },
                {
                    path: "/centers",
                    name: "centers",
                    component: () =>
                        import ("./views/Centers.vue"),
                },
                {
                    path: "/randomization",
                    name: "randomization",
                    component: () =>
                        import ("./views/Randomization.vue"),
                },
            ],
        },
        {
            path: "/",
            redirect: "login",
            component: AuthLayout,
            children: [{
                path: "/login",
                name: "login",
                component: () =>
                    import ( /* webpackChunkName: "demo" */ "./views/Login.vue"),
            }, ],
        },
    ],
});