import axios from "axios";

export default {
  namespaced: true,

  state: {}, //STATE

  getters: {}, //GETTERS

  mutations: {}, // MUTATIONS

  actions: {
    FETCH_USERS: ({ rootState }) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}users`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.auth.token}`,
          },
        })
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    FETCH_APP_USER: ({ rootState }, id) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}users/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.auth.token}`,
          },
        })
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    ADD_USER: ({ rootState }, user) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}users`,
          data: user,
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootState.auth.token}`,
          },
        })
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    UPDATE_USER: ({ rootState }, user) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}users/${user.id}`,
          data: user,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${rootState.auth.token}`,
          },
        })
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    DELETE_USER: ({ rootState }, id) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}users/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${rootState.auth.token}`,
          },
        })
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
  }, //ACTIONS
};
