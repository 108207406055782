<template>
  <base-loader v-if="$wait.is('fetchUser')" />
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" v-else>
    <side-bar
      :background-color="sidebarBackground"
      short-title="CROTURK"
      title="CROTURK"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: t('patients'),
            icon: 'ni ni-badge text-primary',
            path: '/patients',
          }"
        />

        <sidebar-item
          :link="{
            name: t('randomization_name'),
            icon: 'ni ni-archive-2 text-primary',
            path: '/randomization',
          }"
          v-if="user.level === 'MASTER'"
        />
        <sidebar-item
          :link="{
            name: t('centers'),
            icon: 'ni ni-shop text-primary',
            path: '/centers',
          }"
          v-if="user.level === 'MASTER'"
        />
        <sidebar-item
          :link="{
            name: t('users'),
            icon: 'ni ni-circle-08 text-primary',
            path: '/users',
          }"
          v-if="user.level === 'MASTER'"
        />
        <li class="nav-item">
          <a
            @click="exportModalStatus = true"
            class="nav-link"
            style="cursor: pointer"
          >
            <template>
              <i class="ni ni-cloud-download-95 text-primary"></i>
              <span class="nav-link-text">{{ t("download") }}</span>
            </template>
          </a>
        </li>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
    <export-modal :modalStatus.sync="exportModalStatus" />
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

import ExportModal from "../components/Modals/exportModal";

import { mapState } from "vuex";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    ExportModal,
  },
  data() {
    return {
      exportModalStatus: false,
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  created() {
    this.$wait.start("fetchUser");
    this.$store
      .dispatch("auth/FETCH_USER")
      .catch(() => {
        this.$router.push({ name: "login" });
        this.$notify({
          type: "warning",
          title: "Oturum Sonlandırıldı!",
        });
      })
      .finally(() => {
        this.$wait.end("fetchUser");
      });
  },
};
</script>
<style lang="scss"></style>
