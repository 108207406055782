import axios from "axios";

export default {
  namespaced: true,

  state: {
    token: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) || "",
    status: "",
    user: {
      id: null,
      full_name: null,
      email: null,
      level: null,
      phone: null,
      username: null,
    },
  }, //STATE

  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status,
  }, //GETTERS

  mutations: {
    AUTH_REQUEST: (state) => {
      state.status = "loading";
    },
    AUTH_SUCCESS: (state, token) => {
      state.status = "success";
      state.token = token;
    },
    AUTH_ERROR: (state) => {
      state.status = "error";
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
  }, // MUTATIONS

  actions: {
    AUTH_REQUEST: ({ commit }, user) => {
      return new Promise((resolve, reject) => {
        // The Promise used for router redirect in login
        commit("AUTH_REQUEST");
        axios({
          url: `${process.env.VUE_APP_API_URL}login`,
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.token;
            localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, token); // store the token in localstorage
            commit("AUTH_SUCCESS", token);
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR", err);
            localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
            reject(err.response.data);
          });
      });
    },
    AUTH_LOGOUT() {
      return new Promise((resolve) => {
        // commit(AUTH_LOGOUT);
        localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
        resolve();
      });
    },
    FETCH_USER: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}user`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
          .then((resp) => {
            const user = resp.data;
            commit("SET_USER", user);
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR", err);
            localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
            reject(err.response.data);
          });
      });
    },
    UPDATE_USER: ({ state, commit }, payload) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_URL}user`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
          .then((resp) => {
            const user = resp.data.data;
            commit("SET_USER", user);
            resolve(resp);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
  }, //ACTIONS
};
