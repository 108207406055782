import Vue from "vue";
import VueTranslate from "vue-translate-plugin";

import en from "../assets/i18n/en.json";

Vue.use(VueTranslate);

Vue.locales({
  en: en,
});
