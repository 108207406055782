<template>
  <modal
    :show="modalStatus"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-sm"
    @close="onModalClosed"
  >
    <base-loader v-if="$wait.is('export')" />
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-5"
      body-classes="px-lg-5 py-lg-5"
      class="
        border-0
        d-flex
        align-items-center
        justify-content-center
        flex-column
      "
    >
      <small
        class="text-muted d-block my-4 text-center"
        v-if="$wait.is('export')"
      >
        {{ t("download_message") }}
      </small>

      <base-button
        type="success"
        id="download-button"
        tag="a"
        size="lg"
        @click="onModalClosed"
      >
        {{ t("button_download") }}
      </base-button>
    </card>
  </modal>
</template>
<script>
import { Parser } from "json2csv";

export default {
  name: "export-modal",
  data() {
    return {
      data: {},
    };
  },
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    modalStatus(modalStatus) {
      if (modalStatus) {
        this.exportCsv();
      }
    },
  },
  methods: {
    onModalClosed() {
      this.$emit("update:modalStatus", false);
    },
    exportCsv() {
      this.$wait.start("export");
      this.$store
        .dispatch("patient/EXPORT")
        .then(({ data }) => {
          this.data = data;

          try {
            const json2csvParser = new Parser();
            let csv = json2csvParser.parse(data);

            let filename, link;

            filename = "IBS-Export.csv";

            if (!csv.match(/^data:text\/csv/i)) {
              csv = "data:text/csv;charset=utf-8,\uFEFF" + csv;
            }
            data = encodeURI(csv);

            link = document.getElementById("download-button");
            link.setAttribute("href", data);
            link.setAttribute("download", filename);

          } catch (err) {
            console.error(err);
          }

          this.$wait.end("export");
        })
        .catch((err) => {
          this.$wait.end("export");
          this.showError(err);
        });
    },
  },
};
</script>
