<template>
  <div
    class="custom-control custom-radio"
    :class="[inlineClass, { disabled: disabled }]"
  >
    <input
      @click="checkData"
      :id="cbId"
      class="custom-control-input"
      type="radio"
      :disabled="disabled"
      :value="dataValue"
      v-model="model"
    />
    <label :for="cbId" class="custom-control-label">
      <slot></slot>
    </label>
  </div>
</template>
<script>
import { randomString } from "./stringUtils";
export default {
  name: "base-radio",
  props: {
    value: {
      type: [Boolean, String, Number],
    },
    disabled: {
      type: Boolean,
      description: "Whether radio is disabled",
    },
    dataValue: {
      type: [String, Boolean],
      description: "Radio value",
    },
    inline: {
      type: Boolean,
      description: "Whether radio is inline",
    },
  },
  data() {
    return {
      cbId: "",
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return "";
    },
  },
  methods: {
    checkData(e) {
      if (
        ["boolean", "string", "number"].includes(typeof this.model) &&
        e.target.value === this.model.toString()
      ) {
        this.model = null;
      }
    },
  },
  mounted() {
    this.cbId = randomString();
  },
};
</script>
<style lang="scss" scoped>
.custom-radio .custom-control-label::before,
.custom-control-label::after {
  left: -1.8rem !important;
}
</style>
